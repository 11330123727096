import type { EventListView } from "~/business-areas/event/composables/event-list.hook";
import type { EventView } from "~/business-areas/event/composables/event-view.hook";

/*
In memory cache for instant loading from teacher card list to teacher page

Same implementation as course-cache-list.hook.ts, check it for more info.
 */

export function useEventCache() {
  const cache = useState("event_cache", () => new Map<string, EventView>());

  function getBySlug(slug: string) {
    return cache.value.get(slug);
  }

  function setMultiple(events: Array<EventListView | EventView>) {
    for (const event of events) {
      cache.value.set(event.slug, {
        ...event,
        resources: [],
        course: {
          ...event.course,
          audience: [],
          requirements: [],
          outcomes: [],
          teacher: {
            ...event.course.teacher,
            description: "",
            testimonials: [],
          },
        },
      });
    }
  }

  return {
    getBySlug,
    setMultiple,
  };
}
